import * as THREE from 'three'
import { useEffect, useRef, useState } from 'react'
import { Canvas, extend, useThree, useFrame } from '@react-three/fiber'
import {useGLTF, useTexture, Environment, Lightformer, Decal} from '@react-three/drei'
import { BallCollider, CuboidCollider, Physics, RigidBody, useRopeJoint, useSphericalJoint } from '@react-three/rapier'
import { MeshLineGeometry, MeshLineMaterial } from 'meshline'
import { useControls } from 'leva'

extend({ MeshLineGeometry, MeshLineMaterial });
useGLTF.preload('/tag.glb');
useTexture.preload('/band.jpg');
useTexture.preload('/avatar.png');

export default function App() {
  // const { debug } = useControls({ debug: false })
    const debug = false;

  return (
    <Canvas camera={{ position: [0, 0, 13], fov: 25 }}>
      <ambientLight intensity={Math.PI} />
      <Physics debug={debug} interpolate gravity={[0, -40, 0]} timeStep={1 / 60}>
        <Band debug={debug} />
      </Physics>
      <Environment background blur={0.75}>
        <color attach="background" args={['black']} />
        <Lightformer intensity={2} color="white" position={[0, -1, 5]} rotation={[0, 0, Math.PI / 3]} scale={[100, 0.1, 1]} />
        <Lightformer intensity={3} color="white" position={[-1, -1, 1]} rotation={[0, 0, Math.PI / 3]} scale={[100, 0.1, 1]} />
        <Lightformer intensity={3} color="white" position={[1, 1, 1]} rotation={[0, 0, Math.PI / 3]} scale={[100, 0.1, 1]} />
        <Lightformer intensity={10} color="white" position={[-10, 0, 14]} rotation={[0, Math.PI / 2, Math.PI / 3]} scale={[100, 10, 1]} />
      </Environment>
    </Canvas>
  )
}

function Band({ maxSpeed = 50, minSpeed = 10, debug = false }) {

  const { nodes, materials } = useGLTF('/tag.glb') as any;
  const texture = useTexture('/band.jpg');
  const textureAvatar = useTexture('/avatar.png');
  // console.log(nodes);

  const band = useRef<any>(null);
  const fixed = useRef<any>(null);
  const j1 = useRef<any>(null);
  const j2 = useRef<any>(null);
  const j3 = useRef<any>(null);
  const card = useRef<any>(null);
  const vec = new THREE.Vector3(), ang = new THREE.Vector3(), rot = new THREE.Vector3(), dir = new THREE.Vector3();
  const segmentProps = { type: 'dynamic', canSleep: true, colliders: undefined, angularDamping: 2, linearDamping: 2 };


  const {width, height} = useThree((state) => state.size)
  const [curve] = useState(() => new THREE.CatmullRomCurve3([new THREE.Vector3(), new THREE.Vector3(), new THREE.Vector3(), new THREE.Vector3()]))
  const [dragged, drag] = useState<boolean | THREE.Vector3>(false);
  const [hovered, hover] = useState<boolean>(false);

  // dblCardRotation
  const [rotation, setRotation] = useState<any>([0, 0, 0]);
  const [targetRotation, setTargetRotation] = useState<any>([0, 0, 0]);


  const geometry = new MeshLineGeometry();
  const material = new MeshLineMaterial({
    color: "white", resolution: new THREE.Vector2(width, height), useMap: 1, map: texture, repeat: new THREE.Vector2(-3, 1), lineWidth: 1
  });

  useRopeJoint(fixed, j1, [[0, 0, 0], [0, 0, 0], 1]);
  useRopeJoint(j1, j2, [[0, 0, 0], [0, 0, 0], 1]);
  useRopeJoint(j2, j3, [[0, 0, 0], [0, 0, 0], 1]);
  useSphericalJoint(j3, card, [[0, 0, 0], [0, 1.45, 0]]);

  useEffect(() => {
    if (hovered) {
      document.body.style.cursor = dragged ? 'grabbing' : 'grab'
      return () => void (document.body.style.cursor = 'auto')
    }
  }, [hovered, dragged])

  useFrame((state, delta) => {
    if (dragged) {
      vec.set(state.pointer.x, state.pointer.y, 0.5).unproject(state.camera)
      dir.copy(vec).sub(state.camera.position).normalize()
      vec.add(dir.multiplyScalar(state.camera.position.length()));

      [card, j1, j2, j3, fixed].forEach((ref: any) => ref.current?.wakeUp());

      if(dragged instanceof THREE.Vector3) {
        card.current?.setNextKinematicTranslation({ x: vec.x - dragged.x, y: vec.y - dragged.y, z: vec.z - dragged.z })
      }
    }
    if (fixed.current) {

      // Smooth double click rotation
      setRotation((prevRotation: any) => [
        prevRotation[0] + (targetRotation[0] - prevRotation[0]) * 1.3 * delta,
        prevRotation[1] + (targetRotation[1] - prevRotation[1]) * 1.3 * delta,
        prevRotation[2] + (targetRotation[2] - prevRotation[2]) * 1.3 * delta,
      ]);

      // Fix most of the jitter when over pulling the card
      [j1, j2].forEach((ref) => {
        if (!ref.current.lerped) {
          ref.current.lerped = new THREE.Vector3().copy(ref.current.translation())
        }
        const clampedDistance = Math.max(0.1, Math.min(1, ref.current.lerped.distanceTo(ref.current.translation())));
        ref.current.lerped.lerp(ref.current.translation(), delta * (minSpeed + clampedDistance * (maxSpeed - minSpeed)));
      })

      // Calculate catmul curve
      curve.points[0].copy(j3.current.translation());
      curve.points[1].copy(j2.current.lerped);
      curve.points[2].copy(j1.current.lerped);
      curve.points[3].copy(fixed.current.translation());
      band.current.geometry.setPoints(curve.getPoints(32));

      // Tilt it back towards the screen
      ang.copy(card.current.angvel());
      rot.copy(card.current.rotation());
      card.current.setAngvel({ x: ang.x, y: ang.y - rot.y * 0.25, z: ang.z })
    }
  })

  curve.curveType = 'chordal'
  texture.wrapS = texture.wrapT = THREE.RepeatWrapping


  function onDblClickEvent(e: any) {
    // console.log('dblclick')
    // console.log(rotation[1]);
    // if(rotation[1] >= 0) {
    //   setTargetRotation([rotation[0], 0 , rotation[2]]);
    // }else{
    //   setTargetRotation([rotation[0], Math.PI, rotation[2]]);
    // }
    // if(rotation[1] >= 0 && rotation[1] < Math.PI) {
    //   setTargetRotation([rotation[0], rotation[1] + Math.PI + 0.16 , rotation[2]]);
    // }else {
    //   setTargetRotation([rotation[0], 0.0 , rotation[2]]);
    // }
    setTargetRotation([rotation[0], rotation[1] + Math.PI + 0.16 , rotation[2]]);
  }

  return (
    <>
      <group position={[0, 4, 0]}>
        <RigidBody ref={fixed} {...segmentProps} type={'fixed'} />
        <RigidBody ref={j1} position={[0.5, 0, 0]} {...segmentProps} type={'dynamic'}>
          <BallCollider args={[0.1]} />
        </RigidBody>
        <RigidBody position={[1, 0, 0]} ref={j2} {...segmentProps} type={'dynamic'}>
          <BallCollider args={[0.1]} />
        </RigidBody>
        <RigidBody ref={j3} position={[1.5, 0, 0]} {...segmentProps} type={'dynamic'}>
          <BallCollider args={[0.1]} />
        </RigidBody>
        <RigidBody ref={card} position={[2, 0, 0]} {...segmentProps} type={dragged ? 'kinematicPosition' : 'dynamic'}>
          <CuboidCollider args={[0.8, 1.125, 0.01]} />
          <group
            scale={2.25}
            position={[0, -1.2, -0.05]}
            onPointerOver={() => hover(true)}
            onPointerOut={() => hover(false)}
            onPointerUp={(e: any) => {
              e.target.releasePointerCapture(e.pointerId);
              drag(false)}
            }
            onPointerDown={(e: any) => {
              e.target.setPointerCapture(e.pointerId);
              let whereToDrag = new THREE.Vector3().copy(e.point).sub(vec.copy(card.current?.translation()));
              drag(whereToDrag);
            }}>
            <mesh geometry={nodes.card.geometry} rotation={rotation} onDoubleClick={onDblClickEvent}>
              {/*<TextLayer debug={debug} text={'Adam Šmíd'} fontSize={100} fontFamily={'Roboto'} position={[0.0,0.7,0.055]} scale={[0.5, 0.5, 0.1]} rotation={[0,0,0]} />*/}
              <AnimatedText textArray={['Typescript ♥️', 'Angular', 'NEXT.js', 'React', 'Three.js']} fontFamily={'Roboto'} position={[0.0, 0.7, 0.055]} scale={[0.5, 0.5, 0.1]} rotation={[0, 0, 0]}/>
              {/*<AnimatedText textArray={['Typescript', 'React', 'Three.js', 'Three.js']} />*/}

              <Decal scale={[0.3, 0.3, 0.1]} position={[-0.19,0.3,0.052]} rotation={[0,0,0]}>
                    <meshPhysicalMaterial
                      map={textureAvatar}
                      depthTest={true}
                      depthWrite={true}
                      transparent={true}
                      polygonOffset={true}
                      polygonOffsetFactor={-50}
                      map-flipY={true}
                      map-anisotropy={16}
                      roughness={1}
                      clearcoat={1}
                      metalness={1.00}
                      side={THREE.FrontSide}
                      toneMapped={false} />
              </Decal>
              <meshPhysicalMaterial map={materials.base.map} map-anisotropy={16} clearcoat={1} clearcoatRoughness={0.15} roughness={0.3} metalness={0.5} />
            </mesh>
            <mesh geometry={nodes.clip.geometry} material={materials.metal} material-roughness={0.3} />
            <mesh geometry={nodes.clamp.geometry} rotation={rotation} material={materials.metal} />
          </group>
        </RigidBody>
      </group>
      <mesh ref={band} geometry={geometry} material={material}></mesh>
    </>
  )
}


function TextLayer({ text = '', flipY = true, fontSize = 60, fontColor = '#ffffff', fontFamily = 'Arial', width = 512, height = 512, ...props }) {

  const canvasRef = useRef<any>(null);
  const textureRef = useRef<any>(null);

  useEffect(() => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (context === null) return;

    canvas.width = width;
    canvas.height = height;

    context.font = `${fontSize}px ${fontFamily}`;
    context.fillStyle = fontColor;
    context.textAlign = 'center';
    context.textBaseline = 'middle';

    context.fillText(text, canvas.width / 2, canvas.height / 2);

    canvasRef.current = canvas;
    textureRef.current = new THREE.CanvasTexture(canvas);
  }, [fontSize, fontFamily, fontColor, width, height]);

  useEffect(() => {
    if (!canvasRef.current || !textureRef.current) return;
    const context = canvasRef.current.getContext('2d');
    if (context === null) return;

    context.font = `${fontSize}px ${fontFamily}`;
    context.fillStyle = fontColor;
    context.textAlign = 'center';
    context.textBaseline = 'middle';

    context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    context.fillText(text, canvasRef.current.width / 2, canvasRef.current.height / 2);
    textureRef.current.needsUpdate = true;
  }, [text]);


  return (
      (textureRef.current !== null) &&
      <Decal {...props}>
        <meshPhysicalMaterial
            transparent={true}
            map={textureRef.current}
            depthTest={true}
            depthWrite={true}
            polygonOffset={true}
            polygonOffsetFactor={-30}
            map-flipY={true}
            map-anisotropy={16}
            roughness={1}
            clearcoat={1}
            metalness={1.00}
            side={THREE.FrontSide}
            toneMapped={false}
        />
      </Decal>
  )
}

function AnimatedText({ textArray = ['Typescript', 'React', 'Three.js'], speed = 250, pauseDuration = 500, ...props }) {
  const [displayText, setDisplayText] = useState('');
  const [index, setIndex] = useState(0);
  const [subIndex, setSubIndex] = useState(0);
  const [reverse, setReverse] = useState(false);
  const [blink, setBlink] = useState(true);
  const [pause, setPause] = useState(false);

  useEffect(() => {
    // If index exceeds the length of textArray, reset it to 0
    if (index === textArray.length) {
      setIndex(0);
      setSubIndex(0);
      setReverse(false);
      setPause(false);
      return;
    }

    if (subIndex === textArray[index].length + 1 && !reverse && !pause) {
      setPause(true);
      setTimeout(() => {
        setPause(false);
        setReverse(true);
      }, pauseDuration);
      return;
    }

    if (subIndex === 0 && reverse) {
      setReverse(false);
      setIndex((prev) => prev + 1);
      return;
    }

    if (!pause) {
      const timeout = setTimeout(() => {
        setDisplayText((prev) =>
            reverse
                ? prev.slice(0, -1)
                : textArray[index].slice(0, subIndex + 1)
        );
        setSubIndex((prev) => prev + (reverse ? -1 : 1));
      }, speed);

      return () => clearTimeout(timeout);
    }
  }, [subIndex, index, reverse, pause, speed, pauseDuration]);

  useEffect(() => {
    const blinkTimeout = setInterval(() => {
      setBlink(prev => !prev);
    }, 500);

    return () => clearInterval(blinkTimeout);
  }, []);

  return (
      <TextLayer text={`${displayText}${blink ? '|' : ' '}`} {...props} />
  );
}

